//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

//==== Load Slick Slider Component =========
// import '../src/core/slick/slick.js';
import '../src/core/fontawesome.css';
import './assets/js/main.js';

// import './assets/css/animate.css';
// import './assets/css/swiper.min.css';
import './assets/css/jarallax.min.css';
import './assets/css/feather.css';
import './assets/css/style.css';

import '../src/App.css'

import loadeImg from '../src/assets-old/preloader.png';

import Markup from './markup/markup';

function App() {
  return (
    <div className="App">
      <div id="preloader">
            <div className="preloader">
                <span></span>
                <span></span>
            </div>
        </div>
      <Markup />
    </div>
  );
}

export default App;
