import Dropdown from './Dropdown';
const MenuItems = ({ items }) => {
    return (items.has_child==1 ? (
          <>
          <li className="nav-item dropdown">
                <a className='nav-link dropdown-toggle' href={items.url} target={items.target}>
                    {items.title}
                </a>
                <Dropdown submenus={items.childreen} />
              </li>
          </>
        ) : (          
          <li className='nav-item'><a className='nav-link' href={items.url} target={items.target}>{items.title}</a></li>
        )
    );
  };
  
  export default MenuItems;