const Dropdown = ({ submenus }) => {
  return (
    <ul className="dropdown-menu">
      {submenus.map((submenu, index) => (
        <li key={index}>
          <a className="dropdown-item" href={submenu.url} target={submenu.target}><span>{submenu.title}</span></a>
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;