import $ from "jquery";
import Swiper from "swiper";
import SlickSlider from '../../src/core/slick/slick.js';
import '../../src/assets/css/swiper-bundle.min.css'
import 'venobox/dist/venobox.min.css';
import 'venobox/dist/venobox.js';
import '../js/menu';
import './youtube'
import venobox from "venobox/dist/venobox.js";
import 'bootstrap/dist/js/bootstrap.min.js';
import { Offcanvas } from "bootstrap/dist/js/bootstrap.min.js";
function init(){	
	SlickSlider();
	$('#preloader').delay(0).fadeOut('fast');

	const backTotop = function () {
		let e = window.scrollY,
			t = document.querySelector(".back-top");
		if (t) {
			let o = () => t.classList.add("back-top-show"),
				n = () => t.classList.remove("back-top-show");
			window.addEventListener("scroll", (function () {
				e = window.scrollY;
				e >= 800 ? o() : n();
			}));
			t.addEventListener("click", (() => window.scrollTo({
				top: 0,
				behavior: "smooth"
			})))
		}
	}();
const navbar = document.querySelector(".navbar");
const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
const navOffCanvas = document.querySelector(".navbar:not(.navbar-clone) .offcanvas-nav");

let bsOffCanvas;

// Function to toggle the offcanvas navigation menu
function toggleOffCanvas() {
	if (bsOffCanvas) {
		// Show or hide the offcanvas depending on its current state
		if (bsOffCanvas._isShown) {
			bsOffCanvas.hide();
		} else {
			bsOffCanvas.show();
		}
	}
}

// Function to handle dropdown toggle for multi-level dropdown menus
function handleDropdownToggle(event) {
	const currentToggleElement = event.currentTarget;
	const subMenu = currentToggleElement.nextElementSibling;
	
	// Remove 'show' class from any currently open submenus in the same dropdown
	if (!subMenu.classList.contains("show")) {
		// const openSubmenus = currentToggleElement.closest(".dropdown-menu").querySelectorAll(".show");
		// openSubmenus.forEach((submenu) => submenu.classList.remove("show"));
	}

	// Toggle 'show' class on the targeted submenu
	
	subMenu.classList.toggle("show");

	// Close any open submenus when the dropdown is hidden
	const parentDropdown = currentToggleElement.closest("li.nav-item.dropdown.show");
	if (parentDropdown) {
		parentDropdown.addEventListener("hidden.bs.dropdown", () => {
			document.querySelectorAll(".dropdown-submenu .show").forEach((submenu) => {
				submenu.classList.remove("show");
			});
		});
	}

	// Prevent the default link behavior and stop propagation
	event.preventDefault();
	event.stopPropagation();
}

// Initialize the Bootstrap Offcanvas component and set up event listeners
if (navOffCanvas) {
	bsOffCanvas =new Offcanvas(navOffCanvas, { scroll: true });

	// Attach event listeners to the offcanvas toggle buttons
	navOffCanvasBtn.forEach((button) => {
		button.addEventListener("click", toggleOffCanvas);
	});
}

// Attach event listeners to dropdown toggle links for multi-level dropdown functionality
document.querySelectorAll(".dropdown a.dropdown-toggle").forEach((dropdownToggleLink) => {
	dropdownToggleLink.addEventListener("click", handleDropdownToggle);
});

let header = document.querySelector("header nav.navbar"),
	win = window;
win.addEventListener("scroll", (function() {
	(win.scrollY) < 1 ? header.classList.remove("scroll-on") : header.classList.add("scroll-on")
}));
	new venobox({
		selector: ".my-video-links",
		autoplay: !0,
		maxWidth: "1000px"
	});

	new Swiper(".brand-slider", {
		spaceBetween: 20,
		autoplay: {
			delay: 1500
		},
		loop: !0,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 2
			},
			768: {
				slidesPerView: 3
			},
			992: {
				slidesPerView: 5
			}
		}
	});
	$('.popup-video').simpleLightboxVideo();
	$('.testimonial-active').not('.slck-initiailized').slick({
		dots: true,
		infinite: true,
		arrows: false,
		speed: 1000,
		slidesToShow:3,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
}
export default init;