import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.min.js'

const backTotop = function () {
	let e = window.scrollY,
		t = document.querySelector(".back-top");
	if (t) {
		let o = () => t.classList.add("back-top-show"),
			n = () => t.classList.remove("back-top-show");
		window.addEventListener("scroll", (function () {
			e = window.scrollY;
			e >= 800 ? o() : n();
		}));
		t.addEventListener("click", (() => window.scrollTo({
			top: 0,
			behavior: "smooth"
		})))
	}
}();