const Breadcrumb = ({ data , image }) => {
    const headerStyle={
        backgroundColor: "#607D8B",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover", 
        padding: "100px 0 30px" 
    }
    return (
        <section class="bredcrumb-sec" style={headerStyle}>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="promo-txt">
                            <h1 class="text-uppercase text-info mt-3 mb-5 text-center">{data.title}</h1>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    <li class="breadcrumb-item active">{data.title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumb;