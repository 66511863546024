import React, { useState, useEffect } from 'react';
import MenuItems from './MenuItems';
import getBaseUrl from '../../base/htttp';

function Header1() {
	const [data, setData] = useState([]);
	const getData = () => {
		fetch(getBaseUrl() + "menu", { method: 'POST' })
			.then((response) => response.json())
			.then((result) => {
				setData(result.data);
			});
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className="offcanvas-body pt-0 align-items-center">
			<ul className="navbar-nav mx-auto align-items-lg-center">
				{data.map((menu, index) => {
					return <MenuItems items={menu} key={index} />;
				})}
			</ul>
		</div>
	);
}

export default Header1;