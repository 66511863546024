import React from "react";
import HeaderMenu from "./HeaderMenu";
//import MonbileMenu from "./MonbileMenu";
import { useState, useEffect } from "react";
import getBaseUrl from "../../base/htttp";
import 'szmigiel-meanmenu/jquery.meanmenu.min'

function Header1() {
  const [topbar, setTopbar] = useState([]);
  const [ls, setLs] = useState([]);
  const [cs, setCs] = useState([]);
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }
  useEffect(() => {
    fetch(getBaseUrl() + "topbar", { method: 'POST' })
      .then((response) => response.json())
      .then((result) => {
        setTopbar(result.data.content);
        setLs(result.data.ls);
        setCs(result.data.cs);
      });
  }, []);

  return (
    <>
      <header className="header">
        <div className="top-bar bg-sky py-1 header-text-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <p className="d-sm-block"><i className="feather-icon icon-headphones me-2"></i>
                    <a href={cs.contact_phone}>{cs.contact_phone}</a>
                  </p>
                  <p className="d-lg-block text-info"> <i className="feather-icon icon-speaker me-2"></i> {cs.address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg">
          <div className="container px-lg-0">
            <a className="navbar-brand" href="/">
              <img src={ls.logo_url} alt="Logo" />
            </a>
            <button className="navbar-toggler offcanvas-nav-btn" type="button">
              <span className="feather-icon icon-menu"></span>
            </button>
            <div className="offcanvas offcanvas-start offcanvas-nav">
              <div className="offcanvas-header">
                <a href="/" className="text-inverse">
                  <img src={ls.logo_url} alt="Logo" />
                </a>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <HeaderMenu></HeaderMenu>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header1;